import { ActionCreator } from 'redux';
import {
  CLEAR_CERTIFICATE_SEARCH_ACTION,
  CLEAR_COPY_OF_PRODUCT_ACTION,
  CLEAR_MODERATE_ERRORS_ACTION,
  CLEAR_REGNUMBER_ERRORS_ACTION,
  CLEAR_PRODUCT_DATA_ACTION,
  CLEAR_TRANSACTION_ACTION,
  CREATE_CARD_ITEM_ACTION,
  CREATE_CONSUMER_ITEM_ACTION,
  CREATE_CARD_ITEM_FAILED_ACTION,
  CREATE_CONSUMER_ITEM_FAILED_ACTION,
  CREATE_CARD_ITEM_SUCCEEDED_ACTION,
  GENERATE_GTIN_ACTION,
  GET_SUGGESTIONS_ACTION,
  LOAD_CARD_ITEM_ACTION,
  LOAD_CATALOG_CARD_ITEM_ACTION,
  LOAD_CARD_ITEM_FAILED_ACTION,
  LOAD_CARD_ITEM_SUCCEEDED_ACTION,
  LOAD_CARD_ITEM_VERSION_ACTION,
  LOAD_MODAL_ACTION,
  LOAD_MODERATE_ERRORS_ACTION,
  LOAD_MODERATE_ERRORS_FAILED_ACTION,
  LOAD_MODERATE_ERRORS_SUCCEEDED_ACTION,
  LOAD_REGNUMBER_ERRORS_ACTION,
  LOAD_REGNUMBER_ERRORS_FAILED_ACTION,
  LOAD_REGNUMBER_ERRORS_SUCCEEDED_ACTION,
  MODERATE_PRODUCT_ACTION,
  SAVE_PRODUCT_ACTION,
  SAVE_PRODUCT_SUCCEEDED_ACTION,
  SEARCH_STATE_CERTIFICATE_ACTION,
  SEARCH_STATE_CERTIFICATE_FAILED_ACTION,
  SEARCH_STATE_CERTIFICATE_SUCCEEDED_ACTION,
  VALIDATION_GTIN_ACTION,
  VALIDATION_GTIN_PACKAGE_ACTION,
  LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION,
  LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_FAILED_ACTION,
  CREATE_CONSUMER_ITEM_SUCCEEDED_ACTION,
  VALIDATION_GROUP_AND_TRANSPORT_GTIN_ACTION,
  LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION,
  LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_FAILED_ACTION,
  LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION,
  LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_FAILED_ACTION,
  VALIDATION_UNREGISTERED_GTIN_ACTION,
  CLEAR_GPC_AND_TNVED_SELECT_OPTIONS,
  CHECK_TNVED_FOR_MARKING_ACTION,
  CHECK_TNVED_FOR_MARKING_SUCCEEDED_ACTION,
  CHECK_TNVED_FOR_MARKING_FAILED_ACTION,
  CLEAR_TNVED_MARKING_ARRAY,
  LOAD_PHOTO_TYPES_ACTION,
  LOAD_PHOTO_TYPES_ACTION_SUCCEEDED,
  LOAD_PHOTO_TYPES_ACTION_FAILED,
  LOAD_TECHNICAL_OPTIONS_ACTION,
  LOAD_TECHNICAL_OPTIONS_ACTION_SUCCEEDED,
  LOAD_TECHNICAL_OPTIONS_ACTION_FAILED,
  LOAD_TECHNICAL_CATEGORIES_ACTION,
  LOAD_TECHNICAL_CATEGORIES_ACTION_SUCCEEDED,
  LOAD_TECHNICAL_CATEGORIES_ACTION_FAILED,
  CREATE_TECHNICAL_ITEM_ACTION,
  CREATE_TECHNICAL_ITEM_ACTION_SUCCEEDED,
  CREATE_TECHNICAL_ITEM_ACTION_FAILED,
  VALIDATION_TECHNICAL_CARD_ACTION,
  LOAD_COUNTRIES_ACTION,
  LOAD_COUNTRIES_ACTION_SUCCEEDED,
  LOAD_COUNTRIES_ACTION_FAILED,
  CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION,
  CHECK_ACCOUNT_CAN_CREATE_GTIN_SUCCEEDED_ACTION,
  CHECK_ACCOUNT_CAN_CREATE_GTIN_FAILED_ACTION,
  LOAD_ACCOUNT_GCP_LIST_ACTION,
  LOAD_ACCOUNT_GCP_LIST_SUCCEEDED_ACTION,
  LOAD_ACCOUNT_GCP_LIST_FAILED_ACTION,
  CHECK_AVAILABILITY_CREATION_GTIN_ACTION,
  CHECK_AVAILABILITY_CREATION_GTIN_SUCCEEDED_ACTION,
  CHECK_AVAILABILITY_CREATION_GTIN_FAILED_ACTION,
  CLEAR_AVAILABILITY_CREATION_GTIN_ACTION,
  CREATE_GTIN_ACTION,
  CREATE_GTIN_SUCCEEDED_ACTION,
  CREATE_GTIN_FAILED_ACTION,
  CLEAR_GENERATED_GTIN_ACTION,
  REGISTRATION_NUMBER_ACTION,
  REGISTRATION_NUMBER_SUCCEEDED_ACTION,
  CLEAR_RELEASE_FORM_LIST,
  MARKGROUP_CATEGORY_SUCCEEDED_ACTION,
  MARKGROUP_CATEGORY_ACTION,
  PERMIT_NUMBER_ACTION,
  PERMIT_NUMBER_SUCCEEDED_ACTION,
} from './Product.constants';

export const clearReleaseFormList = () => ({
  type: CLEAR_RELEASE_FORM_LIST,
});

export const checkTnvedForMarking: ActionCreator = (data) => ({
  type: CHECK_TNVED_FOR_MARKING_ACTION,
  data,
});

export const checkTnvedForMarkingSucceeded: ActionCreator = (data) => ({
  type: CHECK_TNVED_FOR_MARKING_SUCCEEDED_ACTION,
  data,
});

export const checkTnvedForMarkingFailed: ActionCreator = (data) => ({
  type: CHECK_TNVED_FOR_MARKING_FAILED_ACTION,
  data,
});

export const clearTnvedArrayForMarking: ActionCreator = () => ({
  type: CLEAR_TNVED_MARKING_ARRAY,
});

export const clearGpcAndTnvedSelectOptions: ActionCreator = () => ({
  type: CLEAR_GPC_AND_TNVED_SELECT_OPTIONS,
});

export const loadDictionaryGpcConsumerPackage: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION,
  data: data,
});
export const loadDictionaryGpcConsumerPackageSucceeded: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  data: data,
});
export const loadDictionaryGpcConsumerPackageFailed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_FAILED_ACTION,
  data: data,
});

export const loadDictionaryTnvedConsumerPackage: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION,
  data: data,
});
export const loadDictionaryTnvedConsumerPackageSucceeded: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  data: data,
});
export const loadDictionaryTnvedConsumerPackageFailed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_FAILED_ACTION,
  data: data,
});

export const generationGtin: ActionCreator = (data, cbk = () => null) => ({
  type: GENERATE_GTIN_ACTION,
  data: data,
  cbk: cbk,
});

export const validateGtin: ActionCreator = (data, cbk = () => null) => ({
  type: VALIDATION_GTIN_ACTION,
  data: data,
  cbk: cbk,
});

export const validateGroupAndTransportGtin: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: VALIDATION_GROUP_AND_TRANSPORT_GTIN_ACTION,
  data: data,
  cbk: cbk,
});

export const validateUnregistredGtin: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: VALIDATION_UNREGISTERED_GTIN_ACTION,
  data: data,
  cbk: cbk,
});

export const validateGtinPackage: ActionCreator = (
  gtin,
  level,
  cbk = () => null,
) => ({
  type: VALIDATION_GTIN_PACKAGE_ACTION,
  gtin: gtin,
  level: level,
  cbk: cbk,
});

export const loadCardItem: ActionCreator = (id, dtoName) => ({
  type: LOAD_CARD_ITEM_ACTION,
  id: id,
  dtoName: dtoName,
});

export const loadCatalogCardItem: ActionCreator = (id) => ({
  type: LOAD_CATALOG_CARD_ITEM_ACTION,
  id: id,
});

export const loadCardItemVersion: ActionCreator = (id, versionId) => ({
  type: LOAD_CARD_ITEM_VERSION_ACTION,
  id: id,
  versionId: versionId,
});

export const loadCardItemSucceeded: ActionCreator = (data) => ({
  type: LOAD_CARD_ITEM_SUCCEEDED_ACTION,
  data: data,
});

export const loadCardItemFailed: ActionCreator = () => ({
  type: LOAD_CARD_ITEM_FAILED_ACTION,
});

export const createCardItem: ActionCreator = (
  data,
  mode,
  cbk = () => null,
) => ({
  type: CREATE_CARD_ITEM_ACTION,
  mode: mode,
  data: data,
  cbk: cbk,
});

export const createCardItemSucceeded: ActionCreator = (data) => ({
  type: CREATE_CARD_ITEM_SUCCEEDED_ACTION,
  data: data,
});

export const createCardItemFailed: ActionCreator = () => ({
  type: CREATE_CARD_ITEM_FAILED_ACTION,
});

export const createConsumerPackaging: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: CREATE_CONSUMER_ITEM_ACTION,
  data: data,
  cbk: cbk,
});

export const createConsumerPackagingSucceeded: ActionCreator = (data) => ({
  type: CREATE_CONSUMER_ITEM_SUCCEEDED_ACTION,
  data: data,
});

export const createConsumerPackagingFailed: ActionCreator = () => ({
  type: CREATE_CONSUMER_ITEM_FAILED_ACTION,
});

export const getSuggestions: ActionCreator = (
  url,
  value,
  cbk = () => null,
) => ({
  type: GET_SUGGESTIONS_ACTION,
  url: url,
  value: value,
  cbk: cbk,
});

export const clearTransaction: ActionCreator = (gtin, cbk = () => null) => ({
  type: CLEAR_TRANSACTION_ACTION,
  gtin: gtin,
  cbk: cbk,
});
export const clearCopyOfProduct: ActionCreator = (id, cbk = () => null) => ({
  type: CLEAR_COPY_OF_PRODUCT_ACTION,
  id: id,
  cbk: cbk,
});

export const clearProductData: ActionCreator = () => ({
  type: CLEAR_PRODUCT_DATA_ACTION,
});

export const saveProduct: ActionCreator = (data, cbk) => ({
  type: SAVE_PRODUCT_ACTION,
  data: data,
  cbk: cbk,
});

export const saveProductSucceeded: ActionCreator = (data) => ({
  type: SAVE_PRODUCT_SUCCEEDED_ACTION,
  data: data,
});

export const searchStateCertificate: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: SEARCH_STATE_CERTIFICATE_ACTION,
  data: data,
  cbk: cbk,
});

export const searchStateCertificateSucceeded: ActionCreator = (data) => ({
  type: SEARCH_STATE_CERTIFICATE_SUCCEEDED_ACTION,
  data: data,
});

export const searchStateCertificateFailed: ActionCreator = () => ({
  type: SEARCH_STATE_CERTIFICATE_FAILED_ACTION,
});

export const clearCertificateSearch: ActionCreator = () => ({
  type: CLEAR_CERTIFICATE_SEARCH_ACTION,
});

export const moderateProduct: ActionCreator = (draftId, cbk = () => null) => ({
  type: MODERATE_PRODUCT_ACTION,
  draftId: draftId,
  cbk: cbk,
});

export const loadModal: ActionCreator = (productId, cbk = () => null) => ({
  type: LOAD_MODAL_ACTION,
  productId: productId,
  cbk: cbk,
});

export const loadModerateErrors: ActionCreator = (id) => ({
  type: LOAD_MODERATE_ERRORS_ACTION,
  id: id,
});

export const loadModerateErrorsSucceeded: ActionCreator = (data) => ({
  type: LOAD_MODERATE_ERRORS_SUCCEEDED_ACTION,
  data: data,
});

export const loadModerateErrorsFailed: ActionCreator = () => ({
  type: LOAD_MODERATE_ERRORS_FAILED_ACTION,
});

export const loadRegNumberErrors: ActionCreator = (id) => ({
  type: LOAD_REGNUMBER_ERRORS_ACTION,
  id: id,
});

export const loadRegNumberErrorsSucceeded: ActionCreator = (data) => ({
  type: LOAD_REGNUMBER_ERRORS_SUCCEEDED_ACTION,
  data: data,
});

export const loadRegNumberErrorsFailed : ActionCreator = () => ({
  type: LOAD_REGNUMBER_ERRORS_FAILED_ACTION,
});

export const cleatModerateErrors: ActionCreator = () => ({
  type: CLEAR_MODERATE_ERRORS_ACTION,
});

export const clearRegNumberErrors: ActionCreator = () => ({
  type: CLEAR_REGNUMBER_ERRORS_ACTION,
});

export const loadGcpConsumerPackageDictionary: ActionCreator = () => ({
  type: LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION,
});

export const loadGcpConsumerPackageDictionarySucceeded: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  data: data,
});

export const loadGcpConsumerPackageDictionaryFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_FAILED_ACTION,
});

export const loadPhtotoTypes: ActionCreator = () => ({
  type: LOAD_PHOTO_TYPES_ACTION,
});

export const loadPhtotoTypesSucceeded: ActionCreator = (data) => ({
  type: LOAD_PHOTO_TYPES_ACTION_SUCCEEDED,
  data,
});

export const loadPhtotoTypesFailed: ActionCreator = () => ({
  type: LOAD_PHOTO_TYPES_ACTION_FAILED,
});

export const loadTechnicalOptions: ActionCreator = () => ({
  type: LOAD_TECHNICAL_OPTIONS_ACTION,
});

export const loadTechnicalOptionsSucceeded: ActionCreator = (data) => ({
  type: LOAD_TECHNICAL_OPTIONS_ACTION_SUCCEEDED,
  data,
});

export const loadTechnicalOptionsFailed: ActionCreator = () => ({
  type: LOAD_TECHNICAL_OPTIONS_ACTION_FAILED,
});

export const loadTechnicalCategories: ActionCreator = () => ({
  type: LOAD_TECHNICAL_CATEGORIES_ACTION,
});

export const loadTechnicalCategoriesSucceeded: ActionCreator = (data) => ({
  type: LOAD_TECHNICAL_CATEGORIES_ACTION_SUCCEEDED,
  data,
});

export const loadTechnicalCategoriesFailed: ActionCreator = () => ({
  type: LOAD_TECHNICAL_CATEGORIES_ACTION_FAILED,
});

export const createTechnicalPackaging: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: CREATE_TECHNICAL_ITEM_ACTION,
  data: data,
  cbk: cbk,
});

export const createTechnicalPackagingSucceeded: ActionCreator = (data) => ({
  type: CREATE_TECHNICAL_ITEM_ACTION_SUCCEEDED,
  data: data,
});

export const createTechnicalPackagingFailed: ActionCreator = () => ({
  type: CREATE_TECHNICAL_ITEM_ACTION_FAILED,
});

export const validateTechnicalCard: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: VALIDATION_TECHNICAL_CARD_ACTION,
  data: data,
  cbk: cbk,
});

export const loadCountries: ActionCreator = () => ({
  type: LOAD_COUNTRIES_ACTION,
});

export const loadCountriesSucceeded: ActionCreator = (data) => ({
  type: LOAD_COUNTRIES_ACTION_SUCCEEDED,
  data,
});

export const loadCountriesFailed: ActionCreator = () => ({
  type: LOAD_COUNTRIES_ACTION_FAILED,
});

export const checkAccountCanCreateGtin: ActionCreator = () => ({
  type: CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION,
});

export const checkAccountCanCreateGtinSucceeded: ActionCreator = () => ({
  type: CHECK_ACCOUNT_CAN_CREATE_GTIN_SUCCEEDED_ACTION,
});

export const checkAccountCanCreateGtinFailed: ActionCreator = () => ({
  type: CHECK_ACCOUNT_CAN_CREATE_GTIN_FAILED_ACTION,
});

export const loadAccountGcpList: ActionCreator = () => ({
  type: LOAD_ACCOUNT_GCP_LIST_ACTION,
});

export const loadAccountGcpListSucceeded: ActionCreator = (accountGcpList) => ({
  type: LOAD_ACCOUNT_GCP_LIST_SUCCEEDED_ACTION,
  accountGcpList,
});

export const loadAccountGcpListFailed: ActionCreator = () => ({
  type: LOAD_ACCOUNT_GCP_LIST_FAILED_ACTION,
});

export const checkAvailabilityCreationGtin: ActionCreator = (data, cbk) => ({
  type: CHECK_AVAILABILITY_CREATION_GTIN_ACTION,
  data: data,
  cbk: cbk,
});

export const checkAvailabilityCreationGtinSucceeded: ActionCreator = () => ({
  type: CHECK_AVAILABILITY_CREATION_GTIN_SUCCEEDED_ACTION,
});

export const checkAvailabilityCreationGtinFailed: ActionCreator = () => ({
  type: CHECK_AVAILABILITY_CREATION_GTIN_FAILED_ACTION,
});

export const clearAvailabilityCreationGtin: ActionCreator = () => ({
  type: CLEAR_AVAILABILITY_CREATION_GTIN_ACTION,
});

export const createGtin: ActionCreator = (data, cbk) => ({
  type: CREATE_GTIN_ACTION,
  data: data,
  cbk: cbk,
});

export const createGtinSucceeded: ActionCreator = (generatedGtin) => ({
  type: CREATE_GTIN_SUCCEEDED_ACTION,
  generatedGtin,
});

export const createGtinFailed: ActionCreator = () => ({
  type: CREATE_GTIN_FAILED_ACTION,
});

export const clearGeneratedGtin: ActionCreator = () => ({
  type: CLEAR_GENERATED_GTIN_ACTION,
});

export const registrationNumber: ActionCreator = (regNumber, cbk) => ({
  type: REGISTRATION_NUMBER_ACTION,
  regNumber: regNumber,
  cbk: cbk,
});

export const registrationNumbernSucceeded: ActionCreator = (data) => ({
  type: REGISTRATION_NUMBER_SUCCEEDED_ACTION,
  data,
});
export const markGroupCategory: ActionCreator = (data, cbk) => ({
  type: MARKGROUP_CATEGORY_ACTION,
  data: data,
  cbk: cbk,
});

export const markGroupCategorySucceeded: ActionCreator = (status) => ({
  type: MARKGROUP_CATEGORY_SUCCEEDED_ACTION,
  status,
});
export const permitNumber: ActionCreator = (pdNumber, cbk) => ({
  type: PERMIT_NUMBER_ACTION,
  pdNumber: pdNumber,
  cbk: cbk,
});
export const permitNumberSucceeded: ActionCreator = (status) => ({
  type: PERMIT_NUMBER_SUCCEEDED_ACTION,
  status,
});
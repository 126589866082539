// import { NCALayerConfigBuilder } from '../../common/utils/NCALayer';
import { NCALayerService } from '../../common/utils/NCALayerService';

export class NcalayerMethodsService {
  static signData(data) {
    const method = 'sign';
    const args = {
      format: 'xml',
      data,
      signingParams: {},
      signerParams: {
        extKeyUsageOids: ['1.3.6.1.5.5.7.3.4'],
      },
      locale: 'ru',
    };
    return NCALayerService.init().then(
      () =>
        NCALayerService.getData(method, args)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          })
          .finally(() => NCALayerService.close()),
      (error) => {
        return error;
      },
    );
  }

  // static signCmsData(storageType, data) {
  //   const {
  //     method,
  //     keyType,
  //     attached,
  //   } = new NCALayerConfigBuilder().useSignKeyType().configure();
  //   const args = [storageType, keyType, data, attached];
  //   return NCALayerService.init().then(
  //     () =>
  //       NCALayerService.getData(method, args)
  //         .then((response) => {
  //           return response;
  //         })
  //         .catch((error) => {
  //           return error;
  //         })
  //         .finally(() => NCALayerService.close()),
  //     (error) => {
  //       return error;
  //     },
  //   );
  // }

  // static signCmsDataArray(storageType, data) {
  //   const {
  //     method,
  //     keyType,
  //     attached,
  //   } = new NCALayerConfigBuilder().useSignKeyType().configure();
  //   const args = [storageType, keyType, data, attached];
  //   console.log(args);
  //   return NCALayerService.init().then(
  //     () =>
  //       NCALayerService.getData(method, args)
  //         .then((response) => {
  //           return response;
  //         })
  //         .catch((error) => {
  //           return error;
  //         })
  //         .finally(() => NCALayerService.close()),
  //     (error) => {
  //       return error;
  //     },
  //   );
  // }
}

import { Reducer } from 'redux';
import {
  CLEAR_CERTIFICATE_SEARCH_ACTION,
  CLEAR_MODERATE_ERRORS_ACTION,
  CLEAR_PRODUCT_DATA_ACTION,
  CLEAR_REGNUMBER_ERRORS_ACTION,
  CREATE_CARD_ITEM_ACTION,
  CREATE_CARD_ITEM_FAILED_ACTION,
  CREATE_CONSUMER_ITEM_ACTION,
  CREATE_CONSUMER_ITEM_FAILED_ACTION,
  CREATE_CARD_ITEM_SUCCEEDED_ACTION,
  LOAD_CARD_ITEM_ACTION,
  LOAD_CATALOG_CARD_ITEM_ACTION,
  LOAD_CARD_ITEM_FAILED_ACTION,
  LOAD_CARD_ITEM_SUCCEEDED_ACTION,
  LOAD_CARD_ITEM_VERSION_ACTION,
  LOAD_MODERATE_ERRORS_ACTION,
  LOAD_MODERATE_ERRORS_FAILED_ACTION,
  LOAD_MODERATE_ERRORS_SUCCEEDED_ACTION,
  LOAD_REGNUMBER_ERRORS_SUCCEEDED_ACTION,
  SAVE_PRODUCT_SUCCEEDED_ACTION,
  SEARCH_STATE_CERTIFICATE_ACTION,
  SEARCH_STATE_CERTIFICATE_FAILED_ACTION,
  SEARCH_STATE_CERTIFICATE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION,
  LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_FAILED_ACTION,
  LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION,
  LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_FAILED_ACTION,
  LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION,
  LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_FAILED_ACTION,
  CLEAR_GPC_AND_TNVED_SELECT_OPTIONS,
  CHECK_TNVED_FOR_MARKING_ACTION,
  CHECK_TNVED_FOR_MARKING_SUCCEEDED_ACTION,
  CHECK_TNVED_FOR_MARKING_FAILED_ACTION,
  CLEAR_TNVED_MARKING_ARRAY,
  LOAD_PHOTO_TYPES_ACTION,
  LOAD_PHOTO_TYPES_ACTION_SUCCEEDED,
  LOAD_PHOTO_TYPES_ACTION_FAILED,
  LOAD_TECHNICAL_OPTIONS_ACTION,
  LOAD_TECHNICAL_OPTIONS_ACTION_SUCCEEDED,
  LOAD_TECHNICAL_OPTIONS_ACTION_FAILED,
  LOAD_TECHNICAL_CATEGORIES_ACTION,
  LOAD_TECHNICAL_CATEGORIES_ACTION_SUCCEEDED,
  LOAD_TECHNICAL_CATEGORIES_ACTION_FAILED,
  CREATE_TECHNICAL_ITEM_ACTION,
  LOAD_COUNTRIES_ACTION,
  LOAD_COUNTRIES_ACTION_SUCCEEDED,
  LOAD_COUNTRIES_ACTION_FAILED,
  CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION,
  CHECK_ACCOUNT_CAN_CREATE_GTIN_SUCCEEDED_ACTION,
  CHECK_ACCOUNT_CAN_CREATE_GTIN_FAILED_ACTION,
  LOAD_ACCOUNT_GCP_LIST_ACTION,
  LOAD_ACCOUNT_GCP_LIST_SUCCEEDED_ACTION,
  LOAD_ACCOUNT_GCP_LIST_FAILED_ACTION,
  CHECK_AVAILABILITY_CREATION_GTIN_ACTION,
  CHECK_AVAILABILITY_CREATION_GTIN_SUCCEEDED_ACTION,
  CHECK_AVAILABILITY_CREATION_GTIN_FAILED_ACTION,
  CLEAR_AVAILABILITY_CREATION_GTIN_ACTION,
  CREATE_GTIN_ACTION,
  CREATE_GTIN_SUCCEEDED_ACTION,
  CREATE_GTIN_FAILED_ACTION,
  CLEAR_GENERATED_GTIN_ACTION,
  REGISTRATION_NUMBER_SUCCEEDED_ACTION,
  MARKGROUP_CATEGORY_SUCCEEDED_ACTION,
  CLEAR_RELEASE_FORM_LIST,
  PERMIT_NUMBER_SUCCEEDED_ACTION,
} from './Product.constants';
import { subtractNotNegative } from '../../../common/utils/utils';

const _initialState = {
  loading: 0,
  loadingIsData: 0,
  data: {},
  consumerData: {},
  pdNumberErrors: [],
  searchData: [],
  moderateErrors: [],
  regNumberErrors: [],
  gcpSelectOptions: [],
  tnvedSelectOptions: [],
  gpcSelectOptions: [],
  gpcSelectOptionsTriedToLoad: false,
  tnvedSelectOptionsTriedToLoad: false,
  tnvedArrayForMarking: [],
  photoTypesDictionary: [],
  technicalOptions: [],
  technicalCategories: [],
  countries: [],
  isAccountCanCreateGtin: false,
  isAvailabilityCreationGtin: false,
  generatedGtin: 0,
  accountGcpList: [],
  releaseFormList: [],
  isMarkGroupCategory: false,
};

// const filterFieldsArr = (arr) => {
//   return Object.values(arr.flat().reduce((obj, item) => {
//     if (obj[item.sortOrder]) {
//       const clone = obj[item.sortOrder].find((field) => field.id === item.id);
//
//       if (clone) {
//         return obj;
//       }
//
//       return {
//         ...obj,
//         [item.sortOrder]: [...obj[item.sortOrder], item],
//       };
//     }
//
//     return {
//       ...obj,
//       [item.sortOrder]: [item],
//     };
//   }, {}));
// };

export const productReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case CLEAR_GPC_AND_TNVED_SELECT_OPTIONS: {
      return {
        ...state,
        tnvedSelectOptions: [],
        gpcSelectOptions: [],
        tnvedSelectOptionsTriedToLoad: false,
        gpcSelectOptionsTriedToLoad: false,
      };
    }
    case LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION: {
      return {
        ...state,
        loadingIsData: state.loadingIsData + 1,
        loading: state.loading + 1,
        tnvedSelectOptionsTriedToLoad: false,
      };
    }
    case LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION: {
      return {
        ...state,
        loadingIsData: state.loadingIsData + 1,
        loading: state.loading + 1,
        gpcSelectOptionsTriedToLoad: false,
      };
    }
    case LOAD_CARD_ITEM_ACTION:
    case LOAD_CATALOG_CARD_ITEM_ACTION:
    case CREATE_CARD_ITEM_ACTION: {
      return {
        ...state,
        loadingIsData: state.loadingIsData + 1,
        loading: state.loading + 1,
      };
    }
    case LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_SUCCEEDED_ACTION: {
      return {
        ...state,
        gcpSelectOptions: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }
    case LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_SUCCEEDED_ACTION: {
      return {
        ...state,
        gpcSelectOptions: action.data,
        loadingIsData: subtractNotNegative(state.loadingIsData),
        loading: subtractNotNegative(state.loading),
        gpcSelectOptionsTriedToLoad: true,
      };
    }
    case LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_SUCCEEDED_ACTION: {
      return {
        ...state,
        tnvedSelectOptions: action.data,
        loadingIsData: subtractNotNegative(state.loadingIsData),
        loading: subtractNotNegative(state.loading),
        tnvedSelectOptionsTriedToLoad: true,
      };
    }
    case CREATE_CONSUMER_ITEM_ACTION: {
      return { ...state, data: { ...state.data, ...action.data } };
    }
    case LOAD_CARD_ITEM_VERSION_ACTION:
    case SEARCH_STATE_CERTIFICATE_ACTION:
    case LOAD_MODERATE_ERRORS_ACTION: {
      return { ...state, loading: state.loading + 1 };
    }
    case LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_FAILED_ACTION:
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        tnvedSelectOptionsTriedToLoad: true,
      };
    case LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_FAILED_ACTION:
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        gpcSelectOptionsTriedToLoad: true,
      };
    case LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }
    case LOAD_CARD_ITEM_FAILED_ACTION:
    case CREATE_CARD_ITEM_FAILED_ACTION:
    case CREATE_CONSUMER_ITEM_FAILED_ACTION: {
      return {
        ...state,
        loadingIsData: subtractNotNegative(state.loadingIsData),
        loading: subtractNotNegative(state.loading),
      };
    }
    case SEARCH_STATE_CERTIFICATE_FAILED_ACTION:
    case LOAD_MODERATE_ERRORS_FAILED_ACTION: {
      return { ...state, loading: subtractNotNegative(state.loading) };
    }

    case LOAD_CARD_ITEM_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
        loadingIsData: subtractNotNegative(state.loadingIsData),
        loading: subtractNotNegative(state.loading),
      };
    }

    case CREATE_CARD_ITEM_SUCCEEDED_ACTION: {
      return {
        ...state,
        loadingIsData: subtractNotNegative(state.loadingIsData),
        loading: subtractNotNegative(state.loading),
      };
    }

    case SAVE_PRODUCT_SUCCEEDED_ACTION: {
      return { ...state, data: { ...state.data, ...action.data } };
    }

    case CLEAR_PRODUCT_DATA_ACTION: {
      return {
        ...state,
        data: {},
        loadingIsData: 0,
        loading: 0,
      };
    }

    case SEARCH_STATE_CERTIFICATE_SUCCEEDED_ACTION: {
      return {
        ...state,
        searchData: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case CLEAR_CERTIFICATE_SEARCH_ACTION: {
      return {
        ...state,
        searchData: [],
      };
    }

    case LOAD_MODERATE_ERRORS_SUCCEEDED_ACTION: {
      return {
        ...state,
        moderateErrors: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }
    case CLEAR_MODERATE_ERRORS_ACTION: {
      return { ...state, moderateErrors: [] };
    }
    case LOAD_REGNUMBER_ERRORS_SUCCEEDED_ACTION: {
      return {
        ...state,
        regNumberErrors: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }
    case CLEAR_REGNUMBER_ERRORS_ACTION: {
      return { ...state, regNumberErrors: [] };
    }
    

    case CHECK_TNVED_FOR_MARKING_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case CHECK_TNVED_FOR_MARKING_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        tnvedArrayForMarking: action.data,
      };
    }
    case CHECK_TNVED_FOR_MARKING_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    case CLEAR_TNVED_MARKING_ARRAY: {
      return {
        ...state,
        tnvedArrayForMarking: [],
      };
    }

    case LOAD_PHOTO_TYPES_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case LOAD_PHOTO_TYPES_ACTION_SUCCEEDED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        photoTypesDictionary: action.data,
      };
    }

    case LOAD_PHOTO_TYPES_ACTION_FAILED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_TECHNICAL_OPTIONS_ACTION: {
      return {
        ...state,
        technicalOptions: [],
      };
    }

    case LOAD_TECHNICAL_OPTIONS_ACTION_SUCCEEDED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        technicalOptions: action.data,
      };
    }

    case LOAD_TECHNICAL_OPTIONS_ACTION_FAILED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_TECHNICAL_CATEGORIES_ACTION: {
      return {
        ...state,
        technicalCategories: [],
      };
    }

    case LOAD_TECHNICAL_CATEGORIES_ACTION_SUCCEEDED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        technicalCategories: action.data,
      };
    }

    case LOAD_TECHNICAL_CATEGORIES_ACTION_FAILED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_COUNTRIES_ACTION: {
      return {
        ...state,
        countries: [],
      };
    }

    case LOAD_COUNTRIES_ACTION_SUCCEEDED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        countries: action.data,
      };
    }

    case LOAD_COUNTRIES_ACTION_FAILED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    case CREATE_TECHNICAL_ITEM_ACTION: {
      return { ...state, data: { ...state.data, ...action.data } };
    }

    case CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case CHECK_ACCOUNT_CAN_CREATE_GTIN_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        isAccountCanCreateGtin: true,
      };
    }
    case CHECK_ACCOUNT_CAN_CREATE_GTIN_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        isAccountCanCreateGtin: false,
      };
    }
    //
    case LOAD_ACCOUNT_GCP_LIST_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case LOAD_ACCOUNT_GCP_LIST_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        accountGcpList: action.accountGcpList,
      };
    }
    case LOAD_ACCOUNT_GCP_LIST_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    case CHECK_AVAILABILITY_CREATION_GTIN_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case CHECK_AVAILABILITY_CREATION_GTIN_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        isAvailabilityCreationGtin: true,
      };
    }
    case CHECK_AVAILABILITY_CREATION_GTIN_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        isAvailabilityCreationGtin: false,
      };
    }
    case CLEAR_AVAILABILITY_CREATION_GTIN_ACTION: {
      return {
        ...state,
        isAvailabilityCreationGtin: false,
      };
    }

    case CREATE_GTIN_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case CREATE_GTIN_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        generatedGtin: action.generatedGtin[0],
      };
    }
    case REGISTRATION_NUMBER_SUCCEEDED_ACTION: {
      console.log('Received data:', action.data);
      return {
        ...state,
        releaseFormList: action.data,
      };
    }
    case MARKGROUP_CATEGORY_SUCCEEDED_ACTION: {   
      return {
        ...state,
        isMarkGroupCategory: action.status,
      };
    }
    case PERMIT_NUMBER_SUCCEEDED_ACTION: {
      console.log('Received data:', action.status);
      return {
        ...state,
        isPdNumber: action.status,
      };
    }
    case CLEAR_RELEASE_FORM_LIST: {
      return {
        ...state,
        releaseFormList: [],
      };
    }
    case CREATE_GTIN_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }
    case CLEAR_GENERATED_GTIN_ACTION: {
      return {
        ...state,
        generatedGtin: 0,
      };
    }
    default:
      return { ...state };
  }
};

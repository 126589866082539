import { all, call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import i18n from '../../../i18n';
import { client } from '../../../api/Client';
import { isKZ } from '../../../config';
import { throwNotification } from '../../../common/structure';
import { handleErrorNotification } from '../../ducks/HandleErrors';
import { NcalayerMethodsService } from '../../../common/services/NcalayerMethodsService';

import {
  deleteProductsFailed,
  deleteProductsSuccesed,
  loadDictionaryGoodsBrandSuccesed,
  loadDictionaryGoodsCategorySuccesed,
  loadProductDataFailed,
  loadProductDataSuccesed,
  loadProductVersionsSuccesed,
  openDraftPublishedFailed,
  openDraftPublishedSuccesed,
  importProductSucceeded,
  importProductFailed,
  massMediaImportSucceeded,
  massMediaImportFailed,
  cancelMassMediaImport,
  changeMassUploadListCount,
  cancelAllUploadedMassMediaSucceeded,
  cancelAllUploadedMassMediaFailed,
} from './Products.actions';

import {
  ARCHIVE_CARDS_ACTION,
  DELETE_PRODUCTS_ACTION,
  EXPORT_PRODUCT_ACTION,
  LOAD_DATA_ACTION,
  LOAD_DICTIONARY_GOODS_BRAND_ACTION,
  LOAD_DICTIONARY_GOODS_CATEGORY_ACTION,
  LOAD_VERSIONS_ACTION,
  OPEN_DRAFT_ACTION,
  PUBLISH_PRODUCT_ACTION,
  SEND_FOR_MODERATION_PRODUCT_ACTION,
  IMPORT_PRODUCT_ACTION,
  MASS_MEDIA_IMPORT_ACTION,
  CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION,
  MODERATION_RECALL_ACTION,
} from './Products.constants';

import { abortMassUpload, massUploadListCount } from './Products.selectors';

const addPublishAgreed = (setting, obj) => {
  if (_.has(setting, 'params.publishOnWebsite')) {
    obj = { ...obj, isPublishAgreed: setting.params.publishOnWebsite };
  }
  return obj;
};

/**
 * обращение к crypto-pro плагину.
 */
// function* signingCertificate(message, certificate, cachePin) {
//     if (!_.isNil(certificate)) {
//         return yield signXML(certificate, message, cachePin).then(xml => xml).catch(e => {
//             return {error: e}
//         })
//     } else {
//         return undefined;
//     }
// }

/**
 * обращение к crypto-pro плагину.
 */
// function* signingCertificateArr(messages, certificate, cachePin, fn = null) {
//     const response = [];
//     for (let x = 0; x < messages.length; x++) {
//         const XMLForSign = yield signXML(certificate, messages[x].XMLForSign, cachePin)
//             .then(xml => xml)
//             .catch(e => {
//                 return {error: e}
//             });

//         let obj = {...messages[x], XMLForSign: XMLForSign, isSigned: true};
//         if (_.isFunction(fn)) {
//             obj = fn(obj);
//         }
//         response.push(obj);
//     }
//     return response;
// }

function buildQueryParametersGoodsList(action) {
  let params = {
    pageSize: action.pageSize,
    pageNumber: action.pageNum + 1 || 1,
    sort: action.sort,
    fields: action.fields,
  };

  if (_.isArray(action.sort) || _.isUndefined(action.sort)) {
    const sort = _.defaultTo(_.find(action.sort, 'direction'), {
      field: 'created',
      direction: 'DESC',
    });
    params = _.assign({}, params, { sort: sort });
  }

  let filters = {};
  _.forIn(action.filters, (value, key) => {
    if (!_.isNull(value)) {
      if (key === 'created') {
        if (!_.isNull(value.from) && !_.isNull(value.to)) {
          filters[key] = value;
        }
      } else if (key === 'publicationTime') {
        if (!_.isNull(value.from) && !_.isNull(value.to)) {
          filters[key] = value;
        }
      } else if (key === 'contentProvider') {
        if (!_.isEmpty(value.name)) {
          filters['contentProviderName'] = value.name;
        }
        if (!_.isEmpty(_.head(value.inn))) {
          filters['contentProviderInn'] = value.inn;
        }
      } else if (key === 'photo' || key === 'isValid' || key === 'isExemplar') {
        filters[key] = value;
      } else {
        if (!_.isEmpty(value)) {
          filters[key] = _.has(value, 'id') ? value.id : value;
        }
      }
    }
  });

  return _.assign({}, params, { filter: filters });
}

export function* loadProductData(action) {
  try {
    const params = buildQueryParametersGoodsList(action);
    const response = yield call(
      client().post,
      'goods/list',
      JSON.stringify(params),
    );
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      if (_.get(response, 'error.response.status') === 500) {
        yield put(push('/500'));
      } else {
        yield handleErrorNotification(response.error);
        yield put(loadProductDataFailed());
      }
    } else {
      yield put(
        loadProductDataSuccesed(
          response.data || [],
          response.totalObjects || 0,
          {
            filters: action.filters,
            sort: action.sort,
            pageNum: action.pageNum,
            pageSize: action.pageSize,
          },
        ),
      );
    }
  } catch (e) {
    yield put(loadProductDataFailed());
  }
}

export function* loadProductVersions(action) {
  try {
    const response = yield call(client().get, `goods/versions/${action.id}`);
    if (response.error) {
      yield handleErrorNotification(response.error);
    } else {
      yield put(loadProductVersionsSuccesed(response));
    }
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Неизвестная ошибка при попытке загрузить версионность товара!'),
        'error',
      ),
    );
  }
}

export function* loadDictionaryGoodsBrand(action) {
  try {
    const response = yield call(client().get, '/dictionaries/tm/account');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${i18n.t('Ошибка получения данных о торговых марках!')} Error: ${
          response.error
        }`,
      );
    } else {
      yield put(
        loadDictionaryGoodsBrandSuccesed(_.isArray(response) ? response : []),
      );
    }
  } catch (e) {}
}

export function* loadDictionaryGoodsCategory(action) {
  try {
    const response = yield call(client().get, '/categories/account');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${i18n.t('Ошибка получения данных о категориях!')} Error: ${
          response.error
        }`,
      );
    } else {
      yield put(
        loadDictionaryGoodsCategorySuccesed(
          _.isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {}
}

export function* deleteProducts(action) {
  try {
    const url =
      _.size(action.draftsIds) === 1
        ? `/draft/${_.head(action.draftsIds)}`
        : `/drafts?ids=${JSON.stringify(action.draftsIds)}`;

    const response = yield call(client().delete, url);

    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(deleteProductsFailed());
      action.cbk({ error: i18n.t('Ошибка удаления черновиков') });
    } else {
      yield put(deleteProductsSuccesed(action.draftsIds));
      action.cbk(null);
    }
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Неизвестная ошибка при попытке удалить черновик!'),
        'error',
      ),
    );
    yield put(deleteProductsFailed());
    action.cbk({
      error: i18n.t('Неизвестная ошибка при попытке удалить черновик!'),
    });
  }
}

/**
 *
 * по словам аналитика, при отправке карточки в архив, не нужно задействовать подпись.
 */
export function* archiveCards(action) {
  try {
    const isSigned = isKZ ? false : action.isSigned;
    const message = yield call(
      client().post,
      '/goods/xml-formats',
      JSON.stringify({ goodIds: action.goodsIds }),
    );
    if (message.error) {
      yield handleErrorNotification(message.error);
    } else {
      let resp = [];
      _.forEach(message.data, (i) => {
        resp.push({ ...i, isSigned });
      });
      if (_.isEmpty(resp)) {
        action.cbk({
          error: i18n.t('Ошибки формирования xml для всех товаров'),
        });
      } else {
        const response = yield call(
          client().post,
          '/goods/archive',
          JSON.stringify(resp),
        );
        if (response.error) {
          yield handleErrorNotification(response.error);
        } else {
          action.cbk(null, response);
        }
      }
    }
  } catch (e) {
    action.cbk({ error: e });
  }
}

export function* watchDeleteProducts() {
  yield takeLatest(DELETE_PRODUCTS_ACTION, deleteProducts);
}

export function* moderationProduct(action) {
  try {
    const response = yield call(
      client().post,
      `/draft/send?ids=${JSON.stringify(action.draftsIds)}`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: response.error });
    } else {
      action.cbk(
        _.isEmpty(response.errors) ? null : response.errors,
        response.data,
      );
    }
  } catch (e) {
    action.cbk({ error: e });
  }
}

export function* publishProduct(action) {
  try {
    const fnAddPublishAgreed = _.partial(addPublishAgreed, action.setting);

    let response, data, signedXml, signedXmls;
    let resp = [];
    const setting = action.setting;
    const { isSigned, certId } = setting;
    if (_.size(setting.ids) === 1) {
      console.log(1);
      const p = fnAddPublishAgreed({ goodIds: setting.ids });
      const message = yield call(
        client().post,
        '/goods/xml-format',
        JSON.stringify(p),
      );
      if (message.error) {
        yield handleErrorNotification(message.error);
        return;
      }
      // not relevant
      // const dataForCmsSign = btoa(unescape(encodeURIComponent(message.XMLForSign)));
      if (setting.certificate) {
        /**
         * вместо responseObject возвращать полностью объект
         * и в положительном сценарии смотреть что у ответа код 200
         */

        signedXml = yield call(
          // NcalayerMethodsService.signCmsData, // вызываем при преобразования подписи в формате CMS
          NcalayerMethodsService.signData,
          message.XMLForSign,
        );
      }
      /**
       * если не объект, то скорей всего упало при попытке коннекта к эцп апплету
       * придёт просто string
       */
      if (!_.isObject(signedXml)) {
        action.cbk([signedXml]);
        return;
      }
      /**
       * документация говорит что код 200 вернётся только в случае, если документ подпишется.
       * будем верить.
       *
       * еще в случае если код не 200, message будет не пустой.
       * т.к. спсиска ошибок в открытой документации нет, то просто возращаем ошибку.
       */
      if (
        setting.certificate &&
        _.isObject(signedXml) &&
        signedXml.code !== '200'
      ) {
        action.cbk([signedXml.message]);
        return;
      }

      if (
        setting.certificate &&
        _.isObject(signedXml) &&
        signedXml.code === '200'
      ) {
        const p = fnAddPublishAgreed({
          xml: setting.certificate
            ? signedXml.responseObject[0]
            : message.XMLForSign,
          isSigned,
          certId,
        });
        response = yield call(
          client().post,
          `/goods/publish/${_.head(setting.ids)}`,
          JSON.stringify(p),
        );
      }
    } else {
      const p = fnAddPublishAgreed({ goodIds: setting.ids });
      const message = yield call(
        client().post,
        '/goods/xml-formats',
        JSON.stringify(p),
      );

      const xmlDataArray = _.map(message.data, 'XMLForSign');
      // not relevant
      // const JsonXmlDataArray = JSON.stringify(xmlDataArray);
      // const dataForCmsArray = btoa(unescape(encodeURIComponent(JsonXmlDataArray)));

      if (message.error) {
        yield handleErrorNotification(message.error);
        return;
      } else {
        if (setting.certificate) {
          signedXmls = yield call(
            NcalayerMethodsService.signData,
            xmlDataArray,
          );
        }
        if (!_.isObject(signedXmls)) {
          action.cbk([signedXmls.message]);
          return;
        }
        if (
          setting.certificate &&
          _.isObject(signedXmls) &&
          signedXmls?.code !== '200'
        ) {
          action.cbk([signedXmls.message]);
          return;
        }

        if (
          setting.certificate &&
          _.isObject(signedXmls) &&
          signedXmls?.code === '200' &&
          _.isArray(signedXmls.responseObject)
        ) {
          _.forEach(signedXmls.responseObject, (XMLForSign, index) => {
            resp.push(
              fnAddPublishAgreed({
                XMLForSign,
                goodId: message.data[index].goodId,
                isSigned,
                certId,
              }),
            );
          });
        } else {
          _.forEach(message.data, ({ i }) => {
            resp.push(fnAddPublishAgreed({ i, isSigned, certId }));
            // not relevant
            // resp.push(fnAddPublishAgreed({ goodId, XMLForSign:signedXmls.responseObject, isSigned, certId, dataForCmsSign: dataForCmsArray }));
          });
        }

        response = yield call(
          client().post,
          '/goods/publish',
          JSON.stringify(resp),
        );
      }
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      /**
       * нужно вернуть хоть что-то.
       * но не array
       * иначе упадёт.
       */
      action.cbk({ error: response.error });
    } else {
      if (_.size(setting.ids) === 1) {
        data = { data: [response], errors: [] };
      } else {
        data = response;
      }
      action.cbk(_.isEmpty(data.errors) ? null : data.errors, data.data);
    }
  } catch (e) {
    console.log('error', e);
    action.cbk(e);
  }
}

export function* openDraft(action) {
  try {
    if (action.status === 'published') {
      yield openDraftPublished(action);
    }
  } catch (e) {
    action.cbk({ error: e });
  }
}

function* openDraftPublished(action) {
  try {
    let data;
    const { isExemplar } = action;
    const url =
      _.size(action.ids) === 1
        ? isExemplar
          ? `/goods/exemplar/${_.head(action.ids)}`
          : `/goods/draft/${_.head(action.ids)}`
        : `/goods/drafts?ids=${JSON.stringify(action.ids)}`;

    const response = yield call(client().post, url);
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(openDraftPublishedFailed());
      action.cbk(response.error);
    } else {
      if (_.size(action.ids) === 1) {
        data = { data: [response], errors: [] };
      } else {
        data = response;
      }
      yield put(openDraftPublishedSuccesed(data.data));
      action.cbk(_.isEmpty(data.errors) ? null : data.errors, data.data);
    }
  } catch (e) {
    yield put(openDraftPublishedFailed());
    action.cbk([e]);
  }
}

export function* exportProducts(action) {
  const params = _.pick(buildQueryParametersGoodsList(action.params), [
    'sort',
    'filter',
    'pageNumber',
    'pageSize',
  ]);

  if (!_.isEmpty(action.params.goodIds)) {
    params.filter.goodIds = action.params.goodIds;
  }

  const url =
    action.status === 'full' ? '/goods/reports/full' : '/goods/reports/short';
  try {
    const response = yield call(client().post, url, JSON.stringify(params), {
      responseType: 'arraybuffer',
    });
    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk(response.error);
    } else {
      action.cbk(null, response);
    }
  } catch (e) {
    action.cbk({ error: e });
  }
}

export function* importProducts(action) {
  const { params } = action;
  const url = `/goods/import`;

  try {
    const formData = new FormData();
    formData.append('file', params.file);

    const response = yield call(client().post, url, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    const validationErrors = _.get(
      response,
      'error.response.data.validationErrors',
      null,
    );

    if (validationErrors) {
      yield put(throwNotification(validationErrors.file, `error`));
      yield put(importProductFailed());
      return action.cbk(validationErrors);
    } else if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(importProductFailed());
      action.cbk(response.error);
    } else {
      yield put(importProductSucceeded());
      return action.cbk(null);
    }
  } catch (e) {
    yield put(importProductFailed());
    action.cbk({ error: e });
  }
}

export function* cancelAllUploadedMedia(id) {
  const errT = i18n.t(
    'Произошла ошибка при попытке удаления ранее загруженных файлов',
  );
  const succT = i18n.t('Импорт фотоконтента остановлен!');

  try {
    const response = yield call(
      client().delete,
      `/draft/uploadphotomulti/${id}`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(cancelAllUploadedMassMediaFailed());
    }
    yield put(cancelAllUploadedMassMediaSucceeded());
    yield put(throwNotification(succT, 'success'));
  } catch (e) {
    yield put(cancelAllUploadedMassMediaFailed());
    yield put(throwNotification(errT, `error`));
  }
}

export function* saveMassUploadedMedia(id, cbk) {
  const errT = i18n.t('Произошла ошибка при сохранении фотоконтента!');
  const succT = i18n.t('Импорт медиа успешно завершен!');
  try {
    const response = yield call(
      client().post,
      `/draft/uploadphotomulti/${id}/save`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(massMediaImportFailed());
    }
    if (_.isString(response)) {
      yield put(throwNotification(errT, 'error'));
      return yield put(massMediaImportFailed());
    }
    yield put(massMediaImportSucceeded());
    yield put(throwNotification(succT, 'success'));
    cbk(null);
  } catch (e) {
    yield put(massMediaImportFailed());
    yield put(throwNotification(errT, 'error'));
  }
}

export function* massMediaFileUpload(id, item, imageCounter) {
  const fileError = i18n.t('Не удалось загрузить изображение');
  try {
    const formData = new FormData();
    formData.append('files[]', item, item.name);
    const response = yield call(
      client().post,
      `/draft/uploadphotomulti/${id}`,
      formData,
      {
        headers: { 'content-type': 'multipart/form-data' },
      },
    );

    if (response.error) {
      return yield put(throwNotification(`${fileError} ${item.name}`, 'error'));
    }

    yield put(changeMassUploadListCount(imageCounter));
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Не удалось загрузить изображение') + ` ${item.name}`,
        'error',
      ),
    );
  }
}

export function* massMediaImport(action) {
  const errT = i18n.t('Произошла ошибка при добавлении фотоконтента!');
  const zeroUploaded = i18n.t('Не было загружено ни одного файла');
  const { data, isSameFolder } = action;
  const lastResponse = JSON.parse(
    localStorage.getItem('lastMassUploadStartResponse'),
  );
  let canContinueUpload = false;

  try {
    if (lastResponse?.id) {
      data.resumeId = lastResponse.id;
    }
    const response = yield call(
      client().post,
      `/draft/uploadphotomulti/start `,
      data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(massMediaImportFailed());
    }

    /**
     * Если всё ок, то надо засетать в локал
     * засетать в локалсторедж ответ?
     */
    localStorage.setItem(
      'lastMassUploadStartResponse',
      JSON.stringify(response),
    );

    if (response.skipFiles.length > 0 && isSameFolder) {
      canContinueUpload = true;
    }

    if (action.files && _.isObject(response)) {
      let cancelUpload, uploadedListCound;

      for (let i = 0; i < action.files.length; i++) {
        const count = i + 1;
        cancelUpload = yield select(abortMassUpload);
        if (cancelUpload) {
          yield call(
            cancelAllUploadedMedia,
            canContinueUpload ? lastResponse.id : response.id,
          );
          break;
        }
        if (
          canContinueUpload &&
          _.includes(response.skipFiles, [...action.files][i].name)
        ) {
          yield put(changeMassUploadListCount(count));
          continue;
        }
        yield delay(2000);
        yield call(
          massMediaFileUpload,
          response.id,
          [...action.files][i],
          count,
        );
        uploadedListCound = yield select(massUploadListCount);
      }
      if (!cancelUpload && uploadedListCound) {
        yield call(saveMassUploadedMedia, response.id, action.cbk);
      }
      if (!uploadedListCound) {
        yield put(throwNotification(zeroUploaded, 'error'));
        yield put(massMediaImportFailed());
      }
      yield put(cancelMassMediaImport(false));
    }
    yield put(changeMassUploadListCount(0));
  } catch (e) {
    yield put(massMediaImportFailed());
    yield put(throwNotification(errT, 'error'));
  }
}

export function* moderationRecall(action) {
  const errT = i18n.t('Ошибка при отправке заявки на отзыв из модерации!');
  try {
    const response = yield call(client().post, `/good-recall`, action.data);
    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: [errT] });
    } else {
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: [errT] });
  }
}

export function* watchLoadProductData() {
  yield takeLatest(LOAD_DATA_ACTION, loadProductData);
}

export function* watchLoadProductVersions() {
  yield takeLatest(LOAD_VERSIONS_ACTION, loadProductVersions);
}

export function* watchLoadDictionaryGoodsBrand() {
  yield takeLatest(
    LOAD_DICTIONARY_GOODS_BRAND_ACTION,
    loadDictionaryGoodsBrand,
  );
}

export function* watchLoadDictionaryGoodsCategory() {
  yield takeLatest(
    LOAD_DICTIONARY_GOODS_CATEGORY_ACTION,
    loadDictionaryGoodsCategory,
  );
}

export function* watchModerationProduct() {
  yield takeLatest(SEND_FOR_MODERATION_PRODUCT_ACTION, moderationProduct);
}

export function* watchPublishProduct() {
  yield takeLatest(PUBLISH_PRODUCT_ACTION, publishProduct);
}

export function* watchOpenDraft() {
  yield takeLatest(OPEN_DRAFT_ACTION, openDraft);
}

export function* watchExportProducts() {
  yield takeLatest(EXPORT_PRODUCT_ACTION, exportProducts);
}

export function* watchArchiveCards() {
  yield takeLatest(ARCHIVE_CARDS_ACTION, archiveCards);
}

export function* watchImportProducts() {
  yield takeLatest(IMPORT_PRODUCT_ACTION, importProducts);
}

export function* watchMassMediaImport() {
  yield takeLatest(MASS_MEDIA_IMPORT_ACTION, massMediaImport);
}

export function* watchModerationRecall() {
  yield takeLatest(MODERATION_RECALL_ACTION, moderationRecall);
}

export function* watchCancelAllUploadedMassMedia() {
  yield takeLatest(
    CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION,
    cancelAllUploadedMedia,
  );
}

export default function* productsSaga() {
  yield all([
    watchLoadProductData(),
    watchLoadProductVersions(),
    watchLoadDictionaryGoodsBrand(),
    watchLoadDictionaryGoodsCategory(),
    watchDeleteProducts(),
    watchModerationProduct(),
    watchPublishProduct(),
    watchOpenDraft(),
    watchExportProducts(),
    watchArchiveCards(),
    watchImportProducts(),
    watchMassMediaImport(),
    watchCancelAllUploadedMassMedia(),
    watchModerationRecall(),
  ]);
}
